import { isAxiosError } from 'api/connector'
import { useSelector, useSettings } from 'hooks'
import { IOrganization } from 'providers/Organization/type'
import { useDispatch } from 'react-redux'
import { selectSpecifications, setSpecifications } from 'state'
import { ISpecificationStatus, IStorePerson } from 'state/type'

import * as Type from './type'
import {
  ERefusalReason,
  IOtherSpecificationsProps,
  TSpecification,
} from './type'
import * as Utils from './utils'

const UseSpecifications = (): Type.IUseSpecifications => {
  const specifications = useSelector(selectSpecifications)
  const { getSetting } = useSettings()
  const dispatch = useDispatch()

  const getSpecificationResult = async (
    specificationType: Type.ESpecification,
    organization: IOrganization,
    person: IStorePerson,
    other: IOtherSpecificationsProps
  ): Promise<ISpecificationStatus> => {
    const specification: ISpecificationStatus = {
      isValid: false,
      refusalReason: ERefusalReason.OTHER,
    }
    switch (specificationType) {
      case Type.ESpecification.ACCOUNTING_SETUP: {
        const accountingSpecification =
          await Utils.getAccountingSetupSpecification(getSetting)
        specification.isValid = accountingSpecification.isValid
        specification.refusalReason = accountingSpecification.refusalReason
        break
      }
      case Type.ESpecification.WALLET_VERIFIED: {
        const walletVerifiedSpecification =
          await Utils.getWalletVerifiedSpecification(getSetting, person)
        specification.isValid = walletVerifiedSpecification.isValid
        specification.refusalReason = walletVerifiedSpecification.refusalReason
        break
      }
      case Type.ESpecification.WALLET_CREATED: {
        const walletCreatedSpecification =
          await Utils.getWalletCreatedSpecification(
            getSetting,
            organization,
            person
          )
        specification.isValid = walletCreatedSpecification.isValid
        specification.refusalReason = walletCreatedSpecification.refusalReason
        break
      }
      case Type.ESpecification.EMAILING_HAS_CAMPAIGNS: {
        const emailingHasCampaignsSpecification =
          await Utils.getEmailingHasCampaignsSpecification(organization)
        specification.isValid = emailingHasCampaignsSpecification.isValid
        specification.refusalReason =
          emailingHasCampaignsSpecification.refusalReason
        break
      }
      case Type.ESpecification.IS_MAIN_ORGANIZATION: {
        specification.isValid = organization.isNonprofitMainGroup
        specification.refusalReason = ERefusalReason.UNAUTHORIZED
        break
      }
      case Type.ESpecification.HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP: {
        specification.isValid =
          other.proAccountMember?.user?.hasActiveAccountMembership ??
          // TO DO SIMON: create specifications on isMasterAdmin OR handle this case (mix of spec & roles for access)
          person.isMasterAdmin
        specification.refusalReason =
          ERefusalReason.PRO_ACCOUNT_MEMBERSHIP_INACTIVE
        break
      }
    }
    return specification
  }

  const getSpecification = async (
    specification: Type.ESpecification,
    organization: IOrganization,
    person: IStorePerson,
    other: IOtherSpecificationsProps,
    refreshData = false
  ): Promise<ISpecificationStatus> => {
    const existingSpecification = specifications[specification]
    if (existingSpecification !== undefined && !refreshData) {
      return existingSpecification
    }
    const newSpecification: TSpecification = {}
    try {
      newSpecification[specification] = await getSpecificationResult(
        specification,
        organization,
        person,
        other
      )
    } catch (error: unknown) {
      if (isAxiosError(error) && error.response?.status) {
        return {
          isValid: false,
          refusalReason: Utils.getRefusalReason(error.response.status),
        }
      }
    }
    const specificationsUpdated = {
      ...specifications,
      ...newSpecification,
    }
    dispatch(setSpecifications(specificationsUpdated))
    return specificationsUpdated[specification] as ISpecificationStatus
  }

  const getSpecifications = async (
    specificationsList: Array<Type.ESpecification>,
    organization: IOrganization,
    person: IStorePerson,
    other: IOtherSpecificationsProps = {}
  ) => {
    const specificationsUpdated = await Promise.all(
      specificationsList.map(async (specification) => {
        const spec = await getSpecification(
          specification,
          organization,
          person,
          other
        )
        return { [specification]: spec }
      })
    )

    const specificationsFormatted: { [key: string]: ISpecificationStatus } = {}
    for (const item of specificationsUpdated) {
      const key = String(Object.keys(item)[0])
      specificationsFormatted[key] = item[key]
    }

    return specificationsFormatted
  }

  return {
    getSpecifications,
  }
}

export default UseSpecifications
