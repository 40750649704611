import { IApiProAccountAccountMember } from 'api/type'
import { IOrganization } from 'providers/Organization/type'
import { ISpecificationStatus, IStorePerson } from 'state/type'

export enum ESpecification {
  ACCOUNTING_HAS_ONGOING_PERIOD = 'ACCOUNTING_HAS_ONGOING_PERIOD',
  ACCOUNTING_SETUP = 'ACCOUNTING_SETUP',
  COLLECT = 'COLLECT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  WALLET_VERIFIED = 'WALLET_VERIFIED',
  WALLET_CREATED = 'WALLET_CREATED',
  EMAILING_HAS_CAMPAIGNS = 'EMAILING_HAS_CAMPAIGNS',
  IS_MAIN_ORGANIZATION = 'IS_MAIN_ORGANIZATION',
  HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP = 'HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP',
}

export type TSpecification = { [key: string]: ISpecificationStatus }

export enum ERefusalReason {
  APP_DISABLED = 'APP_DISABLED',
  OTHER = 'OTHER',
  PSP_WALLET_BLOCKED = 'PSP_WALLET_BLOCKED',
  PSP_WALLET_SUSPENDED = 'PSP_WALLET_SUSPENDED',
  SETTING = 'SETTING',
  UNAUTHORIZED = 'UNAUTHORIZED',
  ACCOUNTING_PERIOD = 'ACCOUNTING_PERIOD',
  EMAILING_HAS_NO_CAMPAIGNS = 'EMAILING_HAS_NO_CAMPAIGNS',
  PRO_ACCOUNT_MEMBERSHIP_INACTIVE = 'PRO_ACCOUNT_MEMBERSHIP_INACTIVE',
}

export interface IUseSpecifications {
  getSpecifications: (
    specificationsList: Array<ESpecification>,
    organization: IOrganization,
    person: IStorePerson,
    other?: IOtherSpecificationsProps
  ) => Promise<{ [key: string]: ISpecificationStatus }>
}

export interface IOtherSpecificationsProps {
  proAccountMember?: IApiProAccountAccountMember | null
}
