import {
  IApiBankingSettings,
  IApiNonprofitAccountingSettings,
  IApiNonprofitAccountingSpecification,
  IApiOrganizationCollectSpecifications,
  IApiOrganizationPspSubWallet,
  IApiOrganizationPspWallet,
  IApiOrganizationSubscriptionSpecifications,
  IApiPlatformSpecifications,
} from 'api/type'

export enum ESetting {
  ACCOUNTING = 'ACCOUNTING',
  BANKING_SETTINGS = 'BANKING_SETTINGS',
  COLLECT = 'COLLECT',
  PLATFORM_SPECIFICATIONS = 'PLATFORM_SPECIFICATIONS',
  PSP_SUB_WALLET = 'PSP_SUB_WALLET',
  PSP_WALLET = 'PSP_WALLET',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export type IAccountingSettings = IApiNonprofitAccountingSettings &
  IApiNonprofitAccountingSpecification

export type TSetting =
  | IAccountingSettings
  | IApiBankingSettings
  | IApiOrganizationCollectSpecifications
  | IApiOrganizationSubscriptionSpecifications
  | IApiOrganizationPspSubWallet
  | IApiOrganizationPspWallet
  | IApiPlatformSpecifications
  | null

export type TGetSetting = (
  setting: ESetting,
  refreshData?: boolean,
  errorHandler?:
    | boolean
    | {
        allowedErrorCodes?: Array<number>
        callback?: () => void
      }
) => Promise<TSetting | undefined>

export interface IUseSettings {
  getSetting: TGetSetting
}
