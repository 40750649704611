import useRouter from 'hooks/useRouter'
import { browserHistory } from 'index'
import LayoutBlank from 'layouts/Blank'
import {
  ApplicationsProvider,
  NonprofitProvider,
  OrganizationProvider,
} from 'providers'
import { FC, lazy, ReactNode, Suspense } from 'react'
import {
  Router as ReactRouter,
  Route,
  Switch,
  useParams,
} from 'react-router-dom'

import AdminRouter from './Admin'

const Errors403 = lazy(() => import('pages/Errors/403'))
const NoContent = lazy(() => import('pages/NoContent'))
const LegacyRedirect = lazy(() => import('pages/LegacyRedirect'))

const CollectPublicRouter = lazy(() => import('./CollectPublicRouter'))
const MasterRouter = lazy(() => import('./MasterRouter'))
const PaymentRouter = lazy(() => import('./Payment'))
const SignupRouter = lazy(() => import('./SignupRouter'))
const StorybookRouter = lazy(() => import('./StorybookRouter'))

const OrganizationRoute: FC<{
  children: ReactNode
}> = ({ children }) => {
  const params = useParams<{ organizationId: string }>()

  if (isNaN(parseInt(params.organizationId))) {
    return <Route component={LegacyRedirect} />
  }

  return <>{children}</>
}

const Router: FC = () => {
  const { RouteLayout } = useRouter()

  return (
    <ReactRouter history={browserHistory}>
      <Suspense fallback="">
        <Switch>
          <Route path="/organization/:organizationId*">
            <OrganizationRoute>
              <OrganizationProvider>
                <NonprofitProvider>
                  <ApplicationsProvider>
                    <AdminRouter />
                  </ApplicationsProvider>
                </NonprofitProvider>
              </OrganizationProvider>
            </OrganizationRoute>
          </Route>
          <Route path="/collect/:type/:collectId/:slug">
            <CollectPublicRouter />
          </Route>
          <Route path="/master*">
            <MasterRouter />
          </Route>
          <Route path={['/order*', '/payment*', '/request*']}>
            <PaymentRouter />
          </Route>
          <Route path="/sign-up*">
            <SignupRouter />
          </Route>
          <Route path="/storybook*">
            <StorybookRouter />
          </Route>
          <RouteLayout
            path="/no-content"
            component={NoContent}
            layout={LayoutBlank}
          />
          <RouteLayout
            path="/errors/403"
            component={Errors403}
            layout={LayoutBlank}
          />
          <Route path="*" component={LegacyRedirect} />
        </Switch>
      </Suspense>
    </ReactRouter>
  )
}

export default Router
