import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ECurrency, IStoreOrganization } from 'state/type'
import { EBrand } from 'utils'
import { ELocale } from 'utils/Intl/type'

export const organizationInitialState: IStoreOrganization = {
  '@id': '',
  '@type': 'Organization',
  brand: EBrand.SPRINGLY,
  collectSpecifications: {
    '@context': '',
    '@id': '',
    '@type': '',
    firstDonationCollect: false,
    firstEventCollect: false,
    firstMembershipCollect: false,
    firstProductCollect: false,
    hasAtLeastOneCollectRelatedAppEnabled: false,
    hasAtLeastOneCollect: false,
  },
  crmSpecifications: {
    '@context': '',
    '@id': '',
    '@type': '',
    hasCrm: false,
    hasFullCrm: false,
    hasSimplifiedCrm: false,
    hasAtLeastOneAdvancedChild: false,
    hasMoreThanOneContact: false,
  },
  hasIndependentCommunity: false,
  hasIndependentSite: false,
  id: '',
  idOld: 0,
  iri: '',
  isAccountingConfigured: false,
  isAdvanced: false,
  isLegalIndependent: false,
  isNonprofitMainGroup: false,
  logo: null,
  logoUrl: '',
  name: '',
  parent: null,
  phoneNumber: null,
  nonprofit: {
    '@id': '',
    id: '',
    currency: ECurrency.EUR,
    isDemo: false,
    timezone: 'Europe_Paris',
    locale: ELocale.FR_FR,
    country: 'FR',
    name: '',
    frenchSiren: '',
    frenchSiret: '',
    frenchRna: '',
    employerIdentificationNumber: '',
    platform: '',
    organization: '',
  },
  subscription: null,
  subscriptionSpecifications: {
    '@context': '',
    '@id': '',
    '@type': '',
    canSubscribeToAPayingSubscription: false,
    hasAnActiveSubscription: false,
    hasAllInOneSubscription: false,
    hasFreeSubscription: false,
    hasPayingSubscription: false,
    hasTrialSubscription: false,
    isInRecoverySystem: false,
    isRecoverySystemSuspended: false,
    platformIsImportedFromJoinly: false,
    possibleNewPlans: undefined,
    nonprofitCanHaveAPspWallet: false,
  },
  slug: '',
  createdAt: '',
  url: '',
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState: organizationInitialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<IStoreOrganization>) =>
      Object.assign({}, state, action.payload),
  },
})

export const { setOrganization } = organizationSlice.actions
export default organizationSlice.reducer
