import { API, isAxiosError } from 'api/connector'
import * as Type from 'api/type'
import {
  IAlgoliaKey,
  IApiCheckRemittance,
  IApiNonprofit,
  IApiOrganizationPspWallet,
  IBankAccountsList,
  IMenuItems,
} from 'api/type'
import { IFilterBag } from 'components/Table/type'
import { FormikValues } from 'formik'
import { IStoreOrganization } from 'state/type'
import { alphabeticalSort, iriToId } from 'utils'

export const getOrganizationAddress = async (
  id: string
): Promise<Type.IApiOrganizationAddress> => {
  const address = await API.get(`/api/v1/organizations/${id}/address`)
  return address.data
}

export const getOrganizationPettyCash = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiPettyCash[]> => {
  const params: { [key: string]: boolean } = {
    pagination: false,
    noDependentChild: false,
  }

  const organizationPettyCashes = await API.get(
    `/api/v1/organizations/${organizationId}/petty_cash`,
    { params }
  )

  const alphabeticalSortedAccounts: Type.IApiPettyCash[] =
    organizationPettyCashes.data['hydra:member'].sort(alphabeticalSort)

  const archivedStatusSortedAccounts = alphabeticalSortedAccounts.sort(
    (a, b) => {
      if (a.isArchived && !b.isArchived) {
        return 1
      } else if (!a.isArchived && b.isArchived) {
        return -1
      } else {
        return 0
      }
    }
  )

  return archivedStatusSortedAccounts.map((account: Type.IApiPettyCash) => ({
    ...account,
    ownBankAccount: iriToId(account.organization) === organizationId,
  }))
}

export const getOrganizationBankingSettings = async (
  id: string
): Promise<Type.IApiBankingSettings> => {
  const bankingSettings = await API.get(
    `/api/v1/organizations/${id}/banking_settings`
  )
  return bankingSettings.data
}

export const getOrganizationCollectsSettings = async (
  id: string
): Promise<Type.IApiCollectsSettings> => {
  const collectsSettings = await API.get(
    `/api/v1/organizations/${id}/collects_settings`
  )
  return collectsSettings.data
}

export const getOrganizationCrmSettings = async (
  id: string
): Promise<Type.IApiCrmSettings> => {
  const crmSettings = await API.get(`/api/v1/organizations/${id}/crm_settings`)
  return crmSettings.data
}

export const updateCrmSettings = async (
  id: string,
  settings: FormikValues
): Promise<Type.IApiCrmSettings> =>
  API.put(`/api/v1/crm/crm_feature_settings/${id}`, {
    ...settings,
  })

export const getOrganizationNonprofit = async (
  id: string
): Promise<Type.IApiNonprofit> => {
  const nonprofit = await API.get(`/api/v1/organizations/${id}/nonprofit`)
  return nonprofit.data
}

export const getOrganizationPaymentSettings = async (
  id: string
): Promise<Type.IApiPayment> => {
  const payment = await API.get(`/api/v1/organizations/${id}/payment_settings`)
  return payment.data
}

export const getOrganizationPspWalletPerson = async (
  id: string
): Promise<Type.IApiCrmPerson> => {
  const pspWallets = await getOrganizationPspWallets(id)
  const adyenWallet = pspWallets.find((wallet) => wallet.pspName === 'ADYEN')
  if (!adyenWallet) {
    throw new Error('Adyen wallet not found')
  }
  const person = await API.get(adyenWallet.person)
  // Add person id
  person.data.id = iriToId(adyenWallet.person)
  return person.data
}

export const getOrganizationPspWallet = async (
  id: string
): Promise<Type.IApiOrganizationPspWallet> => {
  const pspWallets = await getOrganizationPspWallets(id)
  const adyenWallet = pspWallets.find((wallet) => wallet.pspName === 'ADYEN')
  if (!adyenWallet) {
    throw new Error('Adyen wallet not found')
  }
  return adyenWallet
}

export const getOrganizationPspWallets = async (
  id: string
): Promise<Type.IApiOrganizationPspWallet[]> => {
  const pspWallet = await API.get(`/api/v1/organizations/${id}/psp_wallets`)
  return pspWallet.data['hydra:member']
}

export const getOrganizationAdyenPspWalletKycSummary = async (
  pspWallet: IApiOrganizationPspWallet
): Promise<Type.IApiPspWalletAdyenKycSummary> => {
  const kycSummary = await API.get(
    `/api/v1/psp_wallets/${pspWallet.id}/kyc_summary`
  )
  return kycSummary.data
}

export const getOrganizationThirdParties = async (
  organizationId: IStoreOrganization['id'],
  params: IFilterBag
): Promise<{
  data: Array<Type.IApiOrganizationAccountingThirdParty>
  totalItems: number
}> => {
  const thirdParties = await API.get(
    `/api/v1/nonprofits/${organizationId}/accounting_third_parties`,
    { params }
  )
  return {
    data: thirdParties.data['hydra:member'],
    totalItems: thirdParties.data['hydra:totalItems'],
  }
}

export const getOrganizationInvoices = async (
  organizationId: IStoreOrganization['id'],
  params: IFilterBag
): Promise<{
  data: Array<Type.IApiOrganizationInvoices>
  totalItems: number
}> => {
  const invoices = await API.get(
    `/api/v1/organizations/${organizationId}/invoices`,
    { params }
  )
  return {
    data: invoices.data['hydra:member'],
    totalItems: invoices.data['hydra:totalItems'],
  }
}

export const updateOrganizationAddress = async (
  id: string,
  address: Type.IApiOrganizationAddressUpdate
): Promise<Type.IApiOrganizationAddress> => {
  try {
    const currentAddress = await API.get(`/api/v1/organizations/${id}/address`)
    // Update
    const updatedAddress = await API.put(
      `/api/v1/organization_addresses/${currentAddress.data.id}`,
      address
    )
    return updatedAddress.data
  } catch (error: unknown) {
    // Create
    if (isAxiosError(error) && error.response?.status === 404) {
      const createdAddress = await API.post(`/api/v1/organization_addresses`, {
        ...address,
        organization: `/api/v1/organizations/${id}`,
      })
      return createdAddress.data
    }
    // Error
    throw error
  }
}

export const updateOrganizationNonprofit = async (
  id: string,
  nonprofit: Type.IApiNonprofitUpdate
): Promise<Type.IApiNonprofit> => {
  const currentLegal = await API.get(`/api/v1/organizations/${id}/nonprofit`)
  const updatedLegal = await API.put(
    `/api/v1/nonprofits/${currentLegal.data.id}`,
    nonprofit
  )
  return updatedLegal.data
}

export const getOrganizationPspSubWallet = async (
  id: string
): Promise<Type.IApiOrganizationPspSubWallet> => {
  const pspSubWallet = await API.get(
    `/api/v1/organizations/${id}/psp_sub_wallet`
  )

  return pspSubWallet.data
}

export const createOrganizationPspSubWallet = async (
  id: IStoreOrganization['id']
): Promise<Type.IApiOrganizationPspSubWallet> =>
  API.post(`/api/v1/organizations/${id}/psp_sub_wallets`, {})

export const getOrganizationAccountingBudgets = async (
  id: IApiNonprofit['id']
): Promise<Type.IApiAccountingBudget[]> => {
  const accountingBudgets = await API.get(
    `/api/v1/nonprofits/${id}/accounting_budgets`,
    { params: { pagination: false } }
  )

  return accountingBudgets.data['hydra:member']
}

export const getCheckRemittances = async (
  organizationId: IStoreOrganization['id'],
  params: IFilterBag
): Promise<{
  data: Array<IApiCheckRemittance>
  totalItems: number
  responseUrl: string
}> => {
  const checkRemittances = await API.get(
    `/api/v1/organizations/${organizationId}/check_remittances`,
    { params }
  )

  return {
    data: checkRemittances.data['hydra:member'],
    totalItems: checkRemittances.data['hydra:totalItems'],
    responseUrl: checkRemittances.request.responseURL,
  }
}

export const sendOrganizationPayout = async ({
  pspSubWalletId,
  bankAccountId,
  amount,
}: {
  pspSubWalletId: string
  bankAccountId: string
  amount?: {
    amount: number
    currency: string
  }
}): Promise<{ id: string }> =>
  API.post(`/api/v1/psp_sub_wallets/${pspSubWalletId}/payout`, {
    bankAccountId,
    amount,
  })

export const getOrganizationAccountingEntries = async (
  organizationId: IStoreOrganization['id'],
  params?: IFilterBag
): Promise<{
  data: Array<Type.IApiAccountingEntry>
  totalItems: number
}> => {
  const entries = await API.get(
    `/api/v1/organizations/${organizationId}/accounting_entries`,
    { params }
  )

  return {
    data: entries.data['hydra:member'],
    totalItems: entries.data['hydra:totalItems'],
  }
}

export const getNonprofitAccountingYears = async (
  nonProfitId: string
): Promise<{
  data: Array<Type.IApiOrganizationAccountingYear>
  totalItems: number
}> => {
  const accountingYear = await API.get(
    `/api/v1/nonprofits/${nonProfitId}/accounting_years`
  )

  return {
    data: accountingYear.data['hydra:member'],
    totalItems: accountingYear.data['hydra:totalItems'],
  }
}

/*
 * Retrieve Accounting Accounts
 */
export const getOrganizationAccountingAccounts = async (
  organizationId: IStoreOrganization['id']
): Promise<{
  data: Array<Type.IApiAccountingAccount>
  totalItems: number
}> => {
  const accountingAccounts = await API.get(
    `/api/v1/organizations/${organizationId}/accounting_accounts`
  )
  return {
    data: accountingAccounts.data['hydra:member'],
    totalItems: accountingAccounts.data['hydra:totalItems'],
  }
}

export const getOrganization = async (
  organizationId: IStoreOrganization['id'] | string // can be either id or idOld
): Promise<Type.IApiOrganization> => {
  const organization = await API.get(`/api/v1/organizations/${organizationId}`)
  return organization?.data || null
}

export const getOrganizationAdvancedGroups = async (
  organizationId: IStoreOrganization['id'],
  filters: FormikValues
): Promise<Type.IGroupsResults> => {
  const params: { [key: string]: boolean } = {
    ...filters,
    isAdvanced: true,
  }
  const groups = await API.get(
    `/api/v1/organizations/${organizationId}/groups`,
    { params }
  )

  return {
    data: groups.data['hydra:member'],
    totalItems: groups.data['hydra:totalItems'],
  }
}

export const getOrganizationGroupsByName = async (
  organizationId: IStoreOrganization['id'],
  groupName: string
): Promise<Type.IApiOrganizationGroup[]> => {
  const groups = await API.get(
    `/api/v1/organizations/${organizationId}/groups?name=${groupName}`
  )
  return groups.data['hydra:member']
}

export const getOrganizationGroups = async (
  organizationId: IStoreOrganization['id'],
  itemsPerPage: number,
  isAdvanced: boolean | undefined = undefined
): Promise<Type.IApiOrganizationGroup[]> => {
  const params: { [key: string]: boolean } = {}
  if (isAdvanced !== undefined) {
    params.isAdvanced = isAdvanced
  }
  const getAllGroupsRecursive = async (
    currentPage: number,
    allGroups: Type.IApiOrganizationGroup[]
  ): Promise<Type.IApiOrganizationGroup[]> => {
    const groups = await API.get(
      `/api/v1/organizations/${organizationId}/groups?itemsPerPage=${itemsPerPage}&page=${currentPage}`,
      { params }
    )

    const allData = [...allGroups, ...groups.data['hydra:member']]

    if (allData.length < groups.data['hydra:totalItems']) {
      return getAllGroupsRecursive(currentPage + 1, allData)
    }

    return allData
  }

  async function getAllGroups() {
    return getAllGroupsRecursive(1, [])
  }
  return getAllGroups()
}

export const getWebhooks = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiCrmWebhook[]> => {
  const webhooks = await API.get(
    `/api/v1/organizations/${organizationId}/webhooks`
  )
  return webhooks.data['hydra:member']
}

export const getOrganizationBankAccounts = async (
  organizationId: IStoreOrganization['id'],
  withDependentChild: boolean,
  withPspDetails: boolean,
  withArchived: boolean
): Promise<IBankAccountsList> => {
  // Fetch bank accounts
  let bankAccounts: Array<
    Type.IApiBankAccount | Type.IApiBankAccountWithPspDetails
  > = await getPaginatedBankAccounts(organizationId, withDependentChild)

  // Filter archivedBankAccounts (if withArchived is true)
  if (!withArchived) {
    bankAccounts = bankAccounts.filter((account) => !account.isArchived)
  }

  // Get pspDetails
  if (withPspDetails) {
    bankAccounts = await getBankAccountsPspDetails(bankAccounts, organizationId)
  }

  bankAccounts = bankAccounts.sort(alphabeticalSort)

  // Order by owner
  return bankAccounts.map((account) => ({
    ...account,
    ownBankAccount: iriToId(account.organization) === organizationId,
  }))
}

const getPaginatedBankAccounts = async (
  organizationId: IStoreOrganization['id'],
  dependentChild: boolean
): Promise<Type.IApiBankAccount[]> => {
  // BankAccounts query split to avoid error 500
  let currentPageTest = 1

  const bankAccounts = await API.get(
    `/api/v1/organizations/${organizationId}/bank_accounts?itemsPerPage=25&page=${currentPageTest}&noDependentChild=${!dependentChild}`
  )

  const allBankAccounts = bankAccounts.data['hydra:member']

  while (allBankAccounts.length < bankAccounts.data['hydra:totalItems']) {
    currentPageTest++
    const tempBankAccounts = await API.get(
      `/api/v1/organizations/${organizationId}/bank_accounts`,
      {
        params: {
          itemsPerPage: 25,
          page: currentPageTest,
          noDependentChild: !dependentChild,
        },
      }
    )

    allBankAccounts.push(...tempBankAccounts.data['hydra:member'])
  }
  return allBankAccounts
}

export const getShortenedBankAccounts = async (
  organizationId: IStoreOrganization['id'],
  dependentChild: boolean
): Promise<Type.IApiBaseBankAccount[]> => {
  // .json to bypass the API normalizer
  const bankAccounts = await API.get(
    `/api/v1/organizations/${organizationId}/bank_accounts.json`,
    {
      params: { noDependentChild: !dependentChild },
      headers: { Accept: 'application/json' },
    }
  )

  const orderedAccounts = [...bankAccounts.data].sort(alphabeticalSort)

  // Order by owner
  return orderedAccounts.map((account) => ({
    ...account,
    ownBankAccount: iriToId(account.organization) === organizationId,
  }))
}

const getBankAccountsPspDetails = async (
  accountsList: Array<Type.IApiBankAccountWithPspDetails>,
  organizationId: IStoreOrganization['id']
) => {
  let pspWallets: Array<Type.IApiOrganizationPspWallet> = []
  try {
    pspWallets = await getOrganizationPspWallets(organizationId)
  } catch (error: unknown) {
    return accountsList
  }

  const adyenPspWallet = pspWallets.find((wallet) => wallet.pspName === 'ADYEN')
  if (!adyenPspWallet) {
    return accountsList
  }

  // PspWalletBankAccounts query split to avoid error 500
  let currentPageTest = 1

  const pspWalletBankAccounts = await API.get(
    `/api/v1/psp_wallets/${adyenPspWallet.id}/psp_bank_accounts?itemsPerPage=25&page=${currentPageTest}`
  )

  const allPspWalletBankAccounts = pspWalletBankAccounts.data['hydra:member']

  while (
    allPspWalletBankAccounts.length <
    pspWalletBankAccounts.data['hydra:totalItems']
  ) {
    currentPageTest++
    const tempPspWalletBankAccounts = await API.get(
      `/api/v1/psp_wallets/${adyenPspWallet.id}/psp_bank_accounts?itemsPerPage=25&page=${currentPageTest}`
    )
    allPspWalletBankAccounts.push(
      ...tempPspWalletBankAccounts.data['hydra:member']
    )
  }

  // Create a details list ordered by bank account id
  const pspDetails: {
    [key: string]: Type.IApiOrganizationPspWalletsPspBankAccounts
  } = {}
  for (const details of allPspWalletBankAccounts) {
    pspDetails[iriToId(details.bankAccount)] = details
  }

  // For every bank account of the organization, add its pspDetails
  for (const bankAccount of accountsList) {
    bankAccount.pspDetails = pspDetails[bankAccount.id]
  }

  return accountsList
}

export const getTaxReceiptsGlobalSettings = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.ITaxReceiptsGlobalSettings> => {
  const taxReceiptsSettings = await API.get(
    `/api/v1/organizations/${organizationId}/tax_receipts/is_enabled`
  )

  return taxReceiptsSettings.data
}

export const updateTaxReceiptsGlobalSetting = async (
  organizationId: IStoreOrganization['id'],
  params: { enable: boolean; date: string }
): Promise<{ isEnabled: boolean }> =>
  API.put(`/api/v1/organizations/${organizationId}/tax_receipts/toggle`, params)

export const getOrganizationSubscriptionSpecifications = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationSubscriptionSpecifications> => {
  const subscriptionSpecifications = await API.get(
    `/api/v1/organizations/${organizationId}/subscription_specifications`
  )
  return subscriptionSpecifications.data
}

export const getOrganizationSubscription = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationSubscription> => {
  const subscription = await API.get(
    `/api/v1/organizations/${organizationId}/subscription`
  )
  return subscription.data
}

export const getOrganizationCollectSpecifications = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationCollectSpecifications> => {
  const collectSpecifications = await API.get(
    `/api/v1/organizations/${organizationId}/collect_specifications`
  )
  return collectSpecifications.data
}

export const getOrganizationCrmSpecifications = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationCrmSpecifications> => {
  const crmSpecifications = await API.get(
    `/api/v1/organizations/${organizationId}/crm_specifications`
  )
  return crmSpecifications.data
}

export const getOrganizationEmailingSpecifications = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationEmailingSpecifications> => {
  const emailingSpecifications = await API.get(
    `/api/v1/organizations/${organizationId}/emailing_specifications`
  )
  return emailingSpecifications.data
}

export const getOrganizationStatsCrm = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationStatsCrm> => {
  const organizationStatsCrm = await API.get(
    `/api/v1/organizations/${organizationId}/stats_crm`
  )
  return organizationStatsCrm.data
}

export const getOrganizationCustomFieldsSchema = async (
  uuid: string,
  contactType: Type.EApiContactType
): Promise<Type.IApiCustomFieldsSchema> => {
  const customFieldsSchema = await API.get(
    `/api/v1/crm/organizations/${uuid}/custom_fields_schema`,
    { params: { contactType } }
  )
  return customFieldsSchema.data
}

export const exportOrganizationCurrentMembers = async (
  organizationId: IStoreOrganization['id'],
  contactType: string
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.get(
    `/api/v1/organizations/${organizationId}/export-center/members/${contactType}/export`
  )
  return exportedData.data
}

export const exportOrganizationOldMembers = async (
  organizationId: IStoreOrganization['id'],
  contactType: string,
  start: string | null,
  end: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.get(
    `/api/v1/organizations/${organizationId}/export-center/old-members/${contactType}/export/${start}/${end}`
  )
  return exportedData.data
}

export const exportOrganizationMembersDuringPeriod = async (
  organizationId: IStoreOrganization['id'],
  contactType: string,
  start: string | null,
  end: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.get(
    `/api/v1/organizations/${organizationId}/export-center/membership/${contactType}/export/${start}/${end}`
  )
  return exportedData.data
}

export const exportOrganizationCurrentDonors = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.get(
    `/api/v1/organizations/${organizationId}/export-center/donors/export`
  )
  return exportedData.data
}

export const exportOrganizationOldDonors = async (
  organizationId: IStoreOrganization['id'],
  year: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.get(
    `/api/v1/organizations/${organizationId}/export-center/inactive-donors/export/${year}`
  )
  return exportedData.data
}
export const exportOrganizationDonorsDuringPeriod = async (
  organizationId: IStoreOrganization['id'],
  start: string | null,
  end: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.get(
    `/api/v1/organizations/${organizationId}/export-center/donations/export/${start}/${end}`
  )
  return exportedData.data
}

export const exportAccountingFec = async (
  organizationId: IStoreOrganization['id'],
  organizationIri: IStoreOrganization['iri'],
  accountingYear: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.post(
    `/api/v1/organizations/${organizationId}/export/accounting/fec`,
    { accountingYear, organization: organizationIri }
  )
  return exportedData.data
}

export const exportAccountingInvoices = async (
  organizationId: IStoreOrganization['id'],
  organizationIri: IStoreOrganization['iri'],
  startsAt: string | null,
  endsAt: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.post(
    `/api/v1/organizations/${organizationId}/export/accounting/entry-documents`,
    { startsAt, endsAt, organization: organizationIri }
  )
  return exportedData.data
}

export const exportAccountingTransactions = async (
  organizationId: IStoreOrganization['id'],
  organizationIri: IStoreOrganization['iri'],
  startsAt: string | null,
  endsAt: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.post(
    `/api/v1/organizations/${organizationId}/export/accounting/transactions`,
    { startsAt, endsAt, organization: organizationIri }
  )
  return exportedData.data
}

export const exportAccountingPayments = async (
  organizationId: IStoreOrganization['id'],
  organizationIri: IStoreOrganization['iri'],
  startsAt: string | null,
  endsAt: string | null
): Promise<Type.IApiOrganizationExport> => {
  const exportedData = await API.post(
    `/api/v1/organizations/${organizationId}/export/accounting/payments`,
    { startsAt, endsAt, organization: organizationIri }
  )
  return exportedData.data
}

export const getOrganizationAccounts = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IApiOrganizationAccountingAccounts[]> => {
  const accounts = await API.get(
    `/api/v1/organizations/${organizationId}/accounting_accounts`
  )
  return accounts.data['hydra:member']
}

export const getOrganizationCollects = async (
  organizationId: IStoreOrganization['id'],
  params: FormikValues
): Promise<Type.ICollectResults> => {
  // Special filter for link-accessible collects
  if (params.websitePageId && params.websitePageId === 'ONLY_BY_LINK') {
    delete params.websitePageId
    params['exists[websitePageId]'] = false
  }

  const collects = await API.get(
    `/api/v1/organizations/${organizationId}/collects`,
    { params }
  )
  if (!collects?.data) {
    return {
      data: [],
      totalItems: 0,
    }
  }
  return {
    data: collects.data['hydra:member'],
    totalItems: collects.data['hydra:totalItems'],
  }
}

export const getOrganizationCollectsDetails = async (
  organizationId: IStoreOrganization['id'],
  params: FormikValues
): Promise<Array<Type.ICollectApiDetails>> => {
  const details = await API.get(
    `/api/v1/organizations/${organizationId}/collects?detailed`,
    { params }
  )
  return details.data['hydra:member']
}

/**
 * Get website collect pages for given organization
 * @param organizationId
 */
export const getOrganizationCollectPages = async (
  organizationId: IStoreOrganization['id']
): Promise<Array<Type.ICollectPage>> => {
  const pages = await API.get(`/api/v1/${organizationId}/website/collect_pages`)
  return pages.data
}

export const getOrganizationNotificationSpecifications = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.INotificationSpecifications> => {
  const notifications = await API.get(
    `/api/v1/organizations/${organizationId}/notification_specifications`
  )
  return notifications.data
}

export const getMenuItems = async (
  organizationId: IStoreOrganization['id']
): Promise<IMenuItems> => {
  const params = { organizationId }

  const menuItems = await API.get(`/api/v1/menus/app`, {
    params,
  })

  return menuItems.data.items
}

export const applyForSepa = (
  id: IApiOrganizationPspWallet['compliance']['id']
) =>
  API.put(`/api/v1/psp_wallet_compliances/${id}/transitions/apply_for_sepa`, {})

export const getOrganizationPaymentSpecification = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IPaymentSpecifications> => {
  const paymentSpecification = await API.get(
    `/api/v1/organizations/${organizationId}/payment_specifications`
  )
  return paymentSpecification.data
}

export const getOrganizationLegacyDetails = async (
  organizationId: IStoreOrganization['id']
): Promise<Type.IOrganizationLegacyDetails> => {
  const legacyDetails = await API.get(
    `/api/v1/legacy_details/organization/${organizationId}`
  )
  return legacyDetails.data
}

export const getOrganizationAlgoliaContactConfig = async (
  organizationId: IStoreOrganization['id']
): Promise<IAlgoliaKey> => {
  const algoliaKey = await API.get(
    `/api/v1/organizations/${organizationId}/contact_algolia_key`
  )
  return algoliaKey.data
}

export const getOrganizationAlgoliaThirdPartyConfig = async (
  organizationId: IStoreOrganization['id']
): Promise<IAlgoliaKey> => {
  const algoliaKey = await API.get(
    `/api/v1/organizations/${organizationId}/third_party_algolia_key`
  )
  return algoliaKey.data
}

export const getOrganizationEmailCampaigns = async (
  organizationId: IStoreOrganization['id'],
  params: IFilterBag
): Promise<Type.IApiOrganizationEmailCampaignsResults> => {
  const organizationEmailCampaigns = await API.get(
    `/api/v1/organizations/${organizationId}/communication/email_campaigns`,
    { params }
  )
  return {
    data: organizationEmailCampaigns.data['hydra:member'],
    totalItems: organizationEmailCampaigns.data['hydra:totalItems'],
  }
}
