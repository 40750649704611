import {
  AbsoluteDate,
  Col,
  Contact,
  Display,
  Label,
  Numeric,
  Row,
} from 'components'
import { EContactType } from 'components/Form/Algolia/Contact/type'
import { ENumericType } from 'components/Form/Numeric/type'
import { ISetFieldValue, ISubmitForm } from 'components/Form/type'
import { FormikValues } from 'formik'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { ReactElement } from 'react'
import { EColor } from 'types'

import * as Type from './type'

export const isElementSubmittable = (element: Element): boolean => {
  const conditions = [
    () => !element.id.includes('react-select'), // for <Select> component
    () => !element.classList.contains('flatpickr-input'), // for <AbsoluteDate> component
  ]

  return conditions.every((condition) => condition())
}

export const getFilter = (
  filterType: Type.EFilterType,
  values: FormikValues,
  setFieldValue: ISetFieldValue,
  submitForm: ISubmitForm,
  translation: IUseTranslation
): ReactElement => {
  switch (filterType) {
    case Type.EFilterType.DATE_RANGE:
      return (
        <Col lg={6}>
          <Label>{translation.translate('form.date.label')}</Label>
          <Row className="justify-content-between justify-content-sm-start px-3">
            <Col xs={6} className="px-0 pr-2">
              <Row className="align-items-start mb-3 pl-0 flex-column flex-sm-row">
                <Col md={2}>
                  <Display
                    type="h6"
                    color={EColor.DARK_GREY}
                    className="mb-1 mb-md-0 ml-md-0 mr-2 pt-0 pt-md-2"
                  >
                    {translation.translate(`form.startDate.label`)}
                  </Display>
                </Col>
                <Col md={10}>
                  <AbsoluteDate
                    className="ml-0 ml-md-2 mb-0 mw-100"
                    placeholder={translation.translate(
                      `form.startDate.placeholder`
                    )}
                    name="startDate"
                    onChange={async () => {
                      setFieldValue('period', '')
                      await submitForm()
                    }}
                    max={values.endDate}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6} className="pl-4 pl-md-2">
              <Row className="align-items-start flex-column flex-sm-row">
                <Col md={2} className="px-0">
                  <Display
                    type="h6"
                    color={EColor.DARK_GREY}
                    className="mb-1 mb-md-0 ml-1 ml-md-0 mr-2 pt-0 pt-md-2 text-md-right"
                  >
                    {translation.translate(`form.endDate.label`)}
                  </Display>
                </Col>
                <Col md={10} className="px-0">
                  <AbsoluteDate
                    className="ml-0 ml-md-2 mb-0 mw-100"
                    placeholder={translation.translate(
                      `form.endDate.placeholder`
                    )}
                    name="endDate"
                    onChange={async () => {
                      setFieldValue('period', '')
                      await submitForm()
                    }}
                    min={values.startDate}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )
    case Type.EFilterType.AMOUNT_RANGE:
      return (
        <Col lg={6}>
          <Label>{translation.translate('form.amount.label')}</Label>
          <Row className="justify-content-between justify-content-sm-start px-3">
            <Col xs={6} className="px-0 pr-2">
              <Row className="align-items-start mb-3 flex-column flex-sm-row">
                <Col md={2}>
                  <Display
                    type="h6"
                    color={EColor.DARK_GREY}
                    className="mb-1 mb-md-0 ml-md-0 mr-2 pt-0 pt-md-2"
                  >
                    {translation.translate('form.minAmount.label')}
                  </Display>
                </Col>
                <Col md={10}>
                  <Numeric
                    className="ml-0 ml-md-2 mb-0 mw-100"
                    name="minAmount"
                    min={0}
                    max={values.maxAmount || undefined}
                    numberType={ENumericType.CURRENCY}
                    placeholder={translation.translate(
                      'form.minAmount.placeholder'
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6} className="pl-4 pl-md-2">
              <Row className="align-items-start flex-column flex-sm-row">
                <Col md={2} className="px-0">
                  <Display
                    type="h6"
                    color={EColor.DARK_GREY}
                    className="mb-1 mb-md-0 ml-1 ml-md-0 mr-2 pt-0 pt-md-2 text-md-right"
                  >
                    {translation.translate('form.maxAmount.label')}
                  </Display>
                </Col>
                <Col md={10} className="px-0">
                  <Numeric
                    className="ml-0 ml-md-2 mb-0 mw-100"
                    name="maxAmount"
                    min={values.minAmount || 0}
                    numberType={ENumericType.CURRENCY}
                    placeholder={translation.translate(
                      'form.maxAmount.placeholder'
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )
    case Type.EFilterType.CONTACT:
      return (
        <Col lg={6}>
          <Contact
            contactType={EContactType.CONTACT}
            label={translation.translate('form.person.label')}
            name="person"
            showHelpText={false}
            onSuggestionCleared={async () => {
              await submitForm()
            }}
            onSuggestionSelected={async () => {
              await submitForm()
            }}
          />
        </Col>
      )
    default:
      return <></>
  }
}
