import { IResponsiveIcon } from 'components/Table/type'
import { Dispatch, ReactNode, SetStateAction } from 'react'

export interface ITableFilter {
  loading: boolean
  onSubmitFilter: (() => Promise<void>) & (() => void)
  resetPageNumber: () => void
  resultsNumber: number
  mainFilterField: ReactNode
  setHaveFilter: Dispatch<SetStateAction<boolean>>
  displayMoreFilters: boolean
}

export interface IResultsNumberBadge {
  results: IResponsiveIcon
  noResults: IResponsiveIcon
}

export enum EFilterType {
  AMOUNT_RANGE = 'amount-range',
  CONTACT = 'contact',
  DATE_RANGE = 'date-range',
}
