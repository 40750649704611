import { getOrganization } from 'api'
import { IApiOrganization } from 'api/type'
import { useApiError } from 'hooks'
import { OrganizationContext } from 'providers'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'

const OrganizationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const params = useParams<{ organizationId: string }>()
  const apiError = useApiError()

  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    const orgaId = parseInt(params.organizationId)

    if (params.organizationId !== currentOrganizationId) {
      setCurrentOrganizationId(orgaId.toString())
    }
  }, [params.organizationId, currentOrganizationId])

  const {
    value: organization,
    loading,
    retry,
  } = useAsyncRetry(async () => {
    try {
      if (!currentOrganizationId) {
        return undefined
      }

      const organizationData = await getOrganization(currentOrganizationId)

      const getMainOrganization = (
        parent: IApiOrganization
      ): IApiOrganization => {
        if (parent?.parent) {
          return getMainOrganization(parent.parent)
        }
        return parent
      }

      return {
        ...organizationData,
        idOld: parseInt(params.organizationId || ''),
        mainOrganization:
          getMainOrganization(organizationData) ?? organizationData,
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [currentOrganizationId])

  if (!organization) {
    return <></>
  }

  return (
    <OrganizationContext.Provider value={{ organization, loading, retry }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider
