import { IGrouppedUrlSearchParameters } from './type'

export const groupParametersByKey = (
  array: Array<[string, string]>
): IGrouppedUrlSearchParameters =>
  array.reduce((acc: { [key: string]: string | string[] }, [key, value]) => {
    const currentValue = acc[key]

    switch (typeof currentValue) {
      case 'object':
        currentValue.push(value)
        break
      case 'string':
        acc[key] = [currentValue, value]
        break
      case 'undefined':
        acc[key] = value
        break
      default:
        throw new Error(`Unexpected type for key: ${key}`)
    }

    return acc
  }, {})
