import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStoreSettings } from 'state/type'

export const settingsInitialState: IStoreSettings = {
  ACCOUNTING: undefined,
  BANKING_SETTINGS: undefined,
  COLLECT: undefined,
  SUBSCRIPTION: undefined,
  PLATFORM_SPECIFICATIONS: undefined,
  PSP_SUB_WALLET: undefined,
  PSP_WALLET: undefined,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    resetSettings: () => settingsInitialState,
    setSettings: (state, action: PayloadAction<IStoreSettings>) =>
      action.payload,
  },
})

export const { setSettings, resetSettings } = settingsSlice.actions
export default settingsSlice.reducer
