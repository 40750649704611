import { createContext } from 'react'
import { EBrand } from 'utils'

import { IOrganizationContext } from './type'

const OrganizationContext = createContext<IOrganizationContext>({
  // TO DO SIMON: update this default value to set with undefined values + create a type with loaded values for pages
  organization: {
    '@id': '',
    '@type': '',
    brand: EBrand.ASSOCONNECT,
    createdAt: '',
    id: '',
    idOld: 0,
    isAccountingConfigured: false,
    isAdvanced: false,
    isLegalIndependent: false,
    isNonprofitMainGroup: false,
    logoUrl: '',
    name: '',
    parent: null,
    phoneNumber: '',
    url: '',
    mainOrganization: {
      '@id': '',
      '@type': '',
      brand: EBrand.ASSOCONNECT,
      createdAt: '',
      id: '',
      isAccountingConfigured: false,
      isAdvanced: false,
      isLegalIndependent: false,
      isNonprofitMainGroup: false,
      logoUrl: '',
      name: '',
      parent: null,
      phoneNumber: '',
      url: '',
    },
  },
  loading: false,
  retry: () => undefined,
})

export default OrganizationContext
