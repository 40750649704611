import {
  EAPIPspLifecycleStatus,
  IApiAccountingSettings,
  IApiBankingSettings,
  IApiOrganizationPspSubWallet,
  IApiOrganizationPspWallet,
} from 'api/type'
import { ESetting, TGetSetting } from 'hooks/useSettings/type'
import { IOrganization } from 'providers/Organization/type'
import { ISpecificationStatus, IStorePerson } from 'state/type'

import { getOrganizationEmailCampaigns } from '../../api'
import * as Type from './type'
import { ERefusalReason } from './type'

export const getRefusalReason = (status: number) => {
  switch (status) {
    case 403:
      return Type.ERefusalReason.UNAUTHORIZED
    case 404:
      return Type.ERefusalReason.SETTING
    default:
      return Type.ERefusalReason.OTHER
  }
}

export const getPspWalletRefusalReason = (
  pspWallet: IApiOrganizationPspWallet
): Type.ERefusalReason | null => {
  if (pspWallet.isBlocked) {
    return ERefusalReason.PSP_WALLET_BLOCKED
  }
  if (pspWallet.isSuspended) {
    return ERefusalReason.PSP_WALLET_SUSPENDED
  }
  return null
}

export const getBankAccountsAccess = async (
  organization: IOrganization,
  getSetting: TGetSetting
) => {
  if (organization.isLegalIndependent) {
    return true
  }

  const bankingSettings = (await getSetting(ESetting.BANKING_SETTINGS, false, {
    allowedErrorCodes: [403, 404],
  })) as IApiBankingSettings

  return !!bankingSettings?.ownBankAccounts
}

export const getAccountingSetupSpecification = async (
  getSetting: TGetSetting
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const accountingSetting = (await getSetting(ESetting.ACCOUNTING, false, {
    allowedErrorCodes: [404, 403],
  })) as IApiAccountingSettings

  const isValid = Boolean(
    accountingSetting?.hasOngoingPeriod &&
      accountingSetting?.accountingSetUp &&
      !accountingSetting?.resetInProgress
  )
  specification.isValid = isValid
  specification.refusalReason = isValid ? null : ERefusalReason.SETTING

  // If the user has no ongoing period
  if (
    !isValid &&
    accountingSetting?.accountingSetUp &&
    !accountingSetting?.hasOngoingPeriod
  ) {
    specification.refusalReason = ERefusalReason.ACCOUNTING_PERIOD
  }
  return specification
}

export const getWalletVerifiedSpecification = async (
  getSetting: TGetSetting,
  person: IStorePerson
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const pspWallet = (await getSetting(
    ESetting.PSP_WALLET,
    false,
    false
  )) as IApiOrganizationPspWallet

  const pspSubWallet = (await getSetting(
    ESetting.PSP_SUB_WALLET,
    false,
    false
  )) as IApiOrganizationPspSubWallet

  specification.isValid =
    person.isMasterAdmin ||
    (!pspWallet.isBlocked &&
      !pspWallet.isSuspended &&
      pspSubWallet !== undefined)

  specification.refusalReason = getPspWalletRefusalReason(pspWallet)
  return specification
}

export const getWalletCreatedSpecification = async (
  getSetting: TGetSetting,
  organization: IOrganization,
  person: IStorePerson
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const pspWallet = (await getSetting(
    ESetting.PSP_WALLET,
    false,
    false
  )) as IApiOrganizationPspWallet

  const hasBankAccountsAccess = await getBankAccountsAccess(
    organization,
    getSetting
  )

  const isValid =
    person.isMasterAdmin ||
    (!pspWallet.isSuspended &&
      hasBankAccountsAccess &&
      [EAPIPspLifecycleStatus.PENDING, EAPIPspLifecycleStatus.VALID].includes(
        pspWallet.pspLifecycleStatus
      ))

  specification.isValid = isValid
  specification.refusalReason = isValid ? null : ERefusalReason.SETTING

  return specification
}

export const getEmailingHasCampaignsSpecification = async (
  organization: IOrganization
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const campaigns = await getOrganizationEmailCampaigns(organization.id, {
    page: 1,
    itemsPerPage: 1,
  })
  const isValid = campaigns.totalItems > 0

  specification.isValid = isValid
  specification.refusalReason = isValid
    ? null
    : ERefusalReason.EMAILING_HAS_NO_CAMPAIGNS
  return specification
}
