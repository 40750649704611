import { API } from 'api/connector'
import { IAnalyticsPages } from 'api/type'
import { IStoreOrganization } from 'state/type'

export const getAnalyticsPageInformation = async (
  organizationId: IStoreOrganization['id']
): Promise<IAnalyticsPages> => {
  const pages = await API.get(
    `/api/v1/organizations/${organizationId}/analytics_pages`
  )
  return pages.data['hydra:member']
}
